import env from './env.config'

export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: '门户',
  /**
   * @description token在Cookie中存储的天数，默认1天
   */
  cookieExpires: 1,
  /**
   * @description api请求基础路径
   * 上线正式请把 /src/env.config.js 配置改为 PROD
   * 测试请把 /src/env.config.js 配置改为 DEV
   */
  baseUrl: (() => {
    const result = {
      file: 'https://img.jinshengyun.cn/',
      bsk: 'https://api.jzyedu.cn/'
    }
    if (env === 'DEV') {
      result.xxb = 'https://xxbpcapi.jsytest.top/'
      result.jsy = 'https://jinshengyunapi.jsytest.top/'

    }
    if (env === 'TEST') {
      result.xxb = 'https://csapi.jsytest.top/'
      result.jsy = 'https://jinshengyunapi.jsytest.top/'

    }
    if (env === 'PROD') {
      result.xxb = 'https://api.jzyxxb.cn/'
      result.jsy = 'https://api.jinshengyun.cn/'

    }
    if (env === 'IP_TEST') {
      result.xxb = ' http://172.27.224.90:8090/'

    }
    return result
  })(),
  /**
   * @description 默认打开的首页的路由name值，默认为home
   */
  homeName: 'home'
}
