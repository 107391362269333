<template>
<div id="app">
  <headerBar v-if="headerInfomatian &&is_show==true " :headerInfomatian="headerInfomatian" :infomation="infomations"></headerBar>
  <nav-bar v-if="headerInfomatian &&is_show==true " :infomation="infomations"></nav-bar>
  <keep-alive include="ziyuan">
    <router-view class="router-view"></router-view>
  </keep-alive>
  <div v-if="is_show==true">
    <footerBar v-if="footerInfomation" :footerInfomation="footerInfomation"></footerBar>
  </div>
  <div v-else>
    <footerBar v-if="footerInfomation" :footerInfomation="footerInfomation" style="width: 100%;position: fixed;left: 0;bottom: 0;"></footerBar>
  </div>
</div>
</template>

<script>
import { lookOriginJSY } from '@/utils/linkUtilsJSY.js'
import navBar from '@/components/navBar/index.vue'
import headerBar from '@/components/headerBar'
import footerBar from '@/components/footerBar'
import { mapState } from 'vuex'
import Cookies from 'js-cookie'
export default {
  name: 'App',
  components: {
    headerBar,
    footerBar,
    navBar
  },
  computed: {
    ...mapState({
      infomations: (state) => state.home.dataInfo.filter(item => item.location > 1 && item.location < 10),
      headerInfomatian: (state) => state.home.dataInfo.find(item => item.location == 1),
      footerInfomation: (state) => state.home.dataInfo.find(item => item.location == 10),
    }),
  },
  watch: {
    headerInfomatian(newval, oldval) {
      let url = newval?.detail?.find(item => item.base_id == 39)?.content ?? ''
      if (newval) {
        this.getCreate(url)
        Cookies.set('titleMsg', newval.detail[1].content)
      }
    },
    titleName(newVal) {
      if (newVal) {
        // console.log(this.$route.meta, newVal)
      }
    },
    $route(newVal) {
      if(newVal.name=='login_jcpt'){
        this.is_show=false
      }else{
        this.is_show=true
      }
    }
  },
  data() {
    return {
      lookOriginJSY,
      titleName: '',
      is_show:true,//当前路由是基础平台登录页 不显示底部顶部
    }
  },
  methods: {
    //动态设置浏览器logo
    getCreate(url) {
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = this.lookOriginJSY(url)
      document.getElementsByTagName('head')[0].appendChild(link)
    },
  },
  mounted() {
  }
}
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* background-color: #f5f5f5; */
}

/* 自定义颜色 */
#nprogress .bar {
  background: red !important;
}

.router-view {
  background-color: #fff;
  /* margin-bottom: 80px !important; */
}
</style>
