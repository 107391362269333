import Vue from "vue"
import VueRouter from "vue-router"
import { preRoute } from './util'
import Cookies from 'js-cookie'
import { mh_urlData } from "@/api/index"

// 使用插件
Vue.use(VueRouter)
// 引入store
import store from "@/store"

import routes from "./routes"
import kjRoutes from "./kongjianRoutes"
// console.log(VueRouter.prototype)

// 先把VueRouter原型对象的push。先保存一份
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
// 重写push ｜ replace
// 第一个参数，告诉原来push方法。你往哪里跳转（传递哪些参数）
// 第二个参数，成功的回调
// 第三个参数，失败的回调
//call || apply 区别：相同点，都可以调用函数一次，都可以篡改函数上下文一次
// 不同点：call与apply传递参数：call传递参数用逗号隔开。apply方法执行，传递数组
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject)
  } else {
    originPush.call(this, location, () => { }, () => { })
  }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject)
  } else {
    originReplace.call(this, location, () => { }, () => { })
  }
}
let baseRoutes = preRoute(routes)
let kongjianRoutes = preRoute(kjRoutes)
// 配置路由
let router = new VueRouter({
  mode: 'history',
  // 配置路由
  routes: [
    ...baseRoutes,
    ...kongjianRoutes
  ],
  // 滚动行为
  scrollBehavior (to, from, savedPosition) {
    // 表示滚动条在最上方y:0 【vue3的话用top:0】
    return { y: 0 }
  },
})
// 全局守卫 前置守卫（在路由跳转之前进行判断）
router.beforeEach((to, from, next) => {
  if (Cookies.get('titleMsg')) {
    document.title = to.meta.name + '-' + Cookies.get('titleMsg')
  } else {
    document.title = to.meta.name
  }
  // to:可以获取到要跳转到哪个路由的信息
  // from:可以获取到从哪个路由来的信息
  // next:放行函数
  // 登陆的token
  // let token = store.state.user.token
  // let name = store.state.user.userInfo.name
  // let userInfo = user.userInfo
  // console.log(token)
  // console.log(name)
  // if (token) {
  //     // 用户已经登陆了 不允许去登陆页面
  //     if (to.path == "/login") {
  //         next("/")
  //     } else {
  //         // 登陆之后去其他页面
  //         if (name) {
  //             next()
  //         } else {
  //             console.log("未获取用户信息")
  //             // 派发action
  //             store.dispatch('getUserInfo').then(res => {
  //                 if (res) {
  //                     next()
  //                 }
  //             }).catch(error => {
  //                 // token过期
  //                 store.dispatch('loginOut').then(res => {
  //                     next("/login")
  //                 })
  //             })
  //         }
  //     }
  // } else {
  //     // 未登陆：不能去交易相关，不能去支付相关，不能去个人中心
  //     console.log(to.path)
  //     if (to.path.indexOf("/trade") != -1 || to.path.indexOf('/pay') != -1 || to.path.indexOf('/center') != -1) {
  //         // 把未登陆的时候想去的地方存储在query参数
  //         next('/login?redirect=' + to.path)
  //     } else {
  //         next()
  //     }

  // }
  let allInfo = store.state.home.dataInfo.length
  if (allInfo > 0) {
    next()
  } else {
    store.dispatch('getmenhuIndex').then(res => {
      // console.log("获取菜单")
      // console.log("获取菜单", localStorage.getItem('menhuUrl_organ_id') == null)
      if (res) {
        if (localStorage.getItem('menhuUrl_organ_id') == null) {
          mh_urlData({ url: window.location.origin }).then(res => {
            if (res.data.code == 1) {
              let menhuUrl_organ_id = res.data.data.organ_id
              let sj_jiaoyuju_id = res.data.data.sj_jiaoyuju_id
              localStorage.setItem('menhuUrl_organ_id', menhuUrl_organ_id)
              localStorage.setItem("jiaoyuju_id", sj_jiaoyuju_id)
              next()
            } else {
              Message.error(res.data.msg)
            }
          })
        } else {
          next()
        }
      }
    }).catch(error => {
    })
  }
})
router.afterEach((to, from, next) => {
  if (Cookies.get('titleMsg')) {
    document.title = to.meta.name + '-' + Cookies.get('titleMsg')
  } else {
    document.title = to.meta.name
  }
})
export default router