
// 检验是否有http前缀
export function lookOriginJSY(link) {

    if (!link) {
        return ""
    }
    var Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
    var objExp = new RegExp(Expression);
    // console.log(objExp.test(link));
    // console.log(link)
    if (objExp.test(link)) {
        return link
    } else {
        return 'https://assets.jzyxxb.cn/' + link
    }
}