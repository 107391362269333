import { menhuindexlogin, loginOut, xxb_StudentLogin, xxb_ParentLogin } from '@/api/login'
import { setToken, getToken, deleteToken } from '@/utils/token'
import { storeUserInfo, clearUserInfo } from '@/utils/userInfo'
// 登陆注册
const state = {
  user_id: '',
  organ_id: '',
  branch_id: '',
  user_truename: '',
  branch_name: '',
  position_name: '',
  organ_name: '',
  sheng_id: '',
  shi_id: '',
  quxian_id: '',
  sheng_name: '',
  shi_name: '',
  quxian_name: '',
}
const mutations = {
  GetUserId (state, user_id) {
    state.user_id = user_id
  },
  GetOrganId (state, organ_id) {
    state.organ_id = organ_id
  },
  GetBranchId (state, branch_id) {
    state.branch_id = branch_id
  },
  GetUserTrueName (state, user_truename) {
    state.user_truename = user_truename
  },
  GetBranchName (state, branch_name) {
    state.branch_name = branch_name
  },
  GetPositionName (state, position_name) {
    state.position_name = position_name
  },
  GetOrganName (state, organ_name) {
    state.organ_name = organ_name
  },
  GetShengId (state, sheng_id) {
    state.sheng_id = sheng_id
  },
  GetShiId (state, shi_id) {
    state.shi_id = shi_id
  },
  GetQuxianId (state, quxian_id) {
    state.quxian_id = quxian_id
  },
  GetShengName (state, sheng_name) {
    state.sheng_name = sheng_name
  },
  GetShiName (state, shi_name) {
    state.shi_name = shi_name
  },
  GetQuxianName (state, quxian_name) {
    state.quxian_name = quxian_name
  },
  LOGINOUT (state) {
    state.token = ''
    deleteToken()
    clearUserInfo()
  }
}
const actions = {
  // 获取验证码暂时没用到
  // getCode (context, phone) {
  //   return new Promise((resolve, reject) => {
  //     reqGetCode(phone).then(res => {
  //       if (res.code == 200) {
  //         context.commit('GETCODE', res.data)
  //         resolve('ok')
  //       } else (
  //         reject(new Error('fail'))
  //       )

  //     })
  //   })
  // },
  // 注册
  getRegister (context, data) {
    return new Promise((resolve, reject) => {
      reqRegister(data).then(res => {
        if (res.code == 200) {
          resolve('ok')
        } else {
          reject(Error(res.message))
        }
      })
    })
  },
  // 登陆[token]
  userLogin (context, data) {
    return new Promise((resolve, reject) => {
      menhuindexlogin(data).then(res => {
        if (res.data.code == 1) {
          let dataAll = res.data.data
          context.commit('GetUserId', dataAll.user_id)
          context.commit('GetOrganId', dataAll.organ_id)
          context.commit('GetBranchId', dataAll.branch_id)
          context.commit('GetUserTrueName', dataAll.user_truename)
          context.commit('GetBranchName', dataAll.branch_name)
          context.commit('GetPositionName', dataAll.position_name)
          context.commit('GetOrganName', dataAll.organ_name)
          context.commit('GetShengId', dataAll.sheng_id)
          context.commit('GetShiId', dataAll.shi_id)
          context.commit('GetQuxianId', dataAll.quxian_id)
          context.commit('GetShengName', dataAll.sheng_name)
          context.commit('GetShiName', dataAll.shi_name)
          context.commit('GetQuxianName', dataAll.quxian_name)
          setToken(dataAll.organ_id)
          storeUserInfo(dataAll)
          resolve('ok')
        } else {
          reject(Error(res.data.msg))
        }
      })
    })
  },
  // 学生登录
  studentLogin (context, data) {
    return new Promise((resolve, reject) => {
      xxb_StudentLogin(data).then(res => {
        if (res.data.code == 1) {
          let dataAll = res.data.data
          dataAll.organ_type = "student"
          storeUserInfo(dataAll)
          resolve('ok')
        } else {
          reject(Error(res.data.msg))
        }
      })
    })
  },
  // 家长登录
  parentLogin (context, data) {
    return new Promise((resolve, reject) => {
      xxb_ParentLogin(data).then(res => {
        if (res.data.code == 1) {
          let dataAll = res.data.data
          dataAll.organ_type = "parent"
          dataAll.user_id = "6"
          storeUserInfo(dataAll)
          resolve('ok')
        } else {
          reject(Error(res.data.msg))
        }
      })
    })
  },
  // 退出登陆
  loginOut (context) {
    return new Promise((resolve, reject) => {
      context.commit("LOGINOUT")
      resolve('ok')

    })
  }
}
const getters = {}
export default {
  state,
  mutations,
  actions,
  getters
}