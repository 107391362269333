import Vue from 'vue'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import App from './App.vue'
import VueQriously from 'vue-qriously'
Vue.use(VueQriously)
Vue.config.productionTip = false
// 引入路由
import router from '@/router'
// 引入仓库
import store from '@/store'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import Cookies from 'js-cookie'
import moment from 'moment'  // 导入 moment 库
import md5 from 'js-md5'// md5加密

Vue.use(ViewUI)
Vue.use(Viewer)
Viewer.setDefaults({
  'inline': false, //启用inline模式
  'button': true, //显示右上角关闭按钮
  'navbar': true, //显示缩略图导航
  'title': false, //显示当前图片的标题
  'toolbar': true, //显示工具栏
  'tooltip': true, //显示缩略百分比
  'movable': true, //图片是否可移动
  'zoomable': true, //图片是否可缩放
  'rotatable': true, //图片是否可旋转
  'scalable': true, //图片是否可反转
  'transition': true, //使用css3过度
  'fullscreen': false, //播放时是否全屏
  'keyboard': true, //
})
Vue.prototype.$moment = moment
Vue.prototype.$cookie = Cookies
Vue.prototype.$md5 = md5

Vue.prototype.$bus = new Vue()

new Vue({
  render: h => h(App),
  beforeCreate() {
  },
  router,
  store,
}).$mount('#app')
