<template>
  <div class="demo-login">
    <!-- <yanzheng-image-vue :shoWData="shoWData"
                        @submit="submit"></yanzheng-image-vue> -->
    <Modal v-model="innnerModal"
           @on-visible-change="changeVisible"
           @on-cancel="cancelModal"
           :mask-closable="false"
           footerHide
           width="600px">
      <div style="margin-top: 20px;text-align: center;">
        <Menu mode="horizontal"
              :theme="theme2"
              @on-select="selectName"
              :activeName="chooseCard">
          <MenuItem name="2">教师</MenuItem>
          <MenuItem name="7">学生</MenuItem>
          <MenuItem name="6">家长</MenuItem>
          <MenuItem name="4">机关</MenuItem>
        </Menu>
      </div>
      <Form ref="formCustom"
            :model="formValidate"
            :rules="ruleValidate">
        <!-- 扫码成功显示 -->
        <div v-if="succ"
             style="z-index:99;background-color:#fff;text-align: center;padding:40px;position: absolute;top: 115px;">
          <div style="font-size: 17px;  padding-bottom: 20px;font-weight: bold;">{{title}}</div>
          <img src="@/assets/success.png"
               alt
               style="" />
          <div style="padding:10px 0;">
            <div>扫码成功！</div>
            <div style="font-size: 15px; padding-top: 15px; font-weight: bold;">请在手机上根据提示确认登录！</div>
          </div>
        </div>
        <div>
          <div class="titleCss"
               style="text-align: center;">
            <p v-if="code_show">教之源APP扫码登录</p>
          </div>
          <div v-if="code_show"
               style="text-align: center;">
            <div class="qrcode">
              <qriously :value="qrcode"
                        :size="150" />
            </div>
            <div class="saomaType">
              <p @click="changeLoginType(1)">账号密码登录</p>
            </div>
          </div>
          <template v-else>
            <!-- 学生 -->
            <template v-if="chooseCard == 7">
              <FormItem prop="idCard"
                        key="idCard">
                <Input autocomplete="new-password"
                       placeholder="请输入身份证号"
                       size="large"
                       @on-enter="handleSubmit('formCustom')"
                       v-model="formValidate.idCard"
                       style="width: 100%;">
                <Icon type="ios-contact"
                      slot="prefix"
                      size="22" />
                </Input>
              </FormItem>
            </template>
            <!-- 学校和机关 -->
            <template v-else>
              <FormItem prop="phone"
                        key="phone">
                <Input autocomplete="new-password"
                       placeholder="请输入手机号"
                       size="large"
                       @on-enter="handleSubmit('formCustom')"
                       v-model="formValidate.phone"
                       style="width: 100%;">
                <Icon type="ios-contact"
                      slot="prefix"
                      size="22" />
                </Input>
              </FormItem>
            </template>

            <FormItem prop="password"
                      key="password">
              <Input autocomplete="new-password"
                     placeholder="请输入登录密码"
                     size="large"
                     type="password"
                     @on-enter="handleSubmit('formCustom')"
                     v-model="formValidate.password"
                     style="width: 100%;">
              <Icon type="ios-unlock"
                    slot="prefix"
                    size="22" />
              </Input>
            </FormItem>
            <div style="text-align: center;margin-top: 20px;">
              <Button type="primary"
                      @click="handleSubmit('formCustom')"
                      size="large"
                      shape="circle"
                      style="width:100%;font-weight: bold;font-size: 20px;">登&ensp;录</Button>
            </div>
            <div class="saomaType">
              <p @click="changeLoginType(2)">扫码登录</p>
            </div>
          </template>
        </div>
      </Form>
    </Modal>
  </div>
</template>
<script>
import { getLoginCode, getUserDataForCode, getStudentJiazhangLoginCode, getStudentJiazhangDataForCode } from '../../api/login'
import { storeUserInfo, clearUserInfo } from '@/utils/userInfo'
import yanzhengImageVue from '@/components/yanzhengImage.vue'
import AES_SECRET from "@/libs/Encryption.js"
import { Base64 } from 'js-base64'
import { FormItem } from 'view-design'
let timer = null
export default {
  components: {
    yanzhengImageVue
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    modal (val) {
      this.innnerModal = val
      // this.code_show = true
      // this.showQrcode()
    },
  },
  // headerBar里边传递过来的参数
  created () {
    this.$bus.$on('selectName', res => {
      this.chooseCard = res
      console.log('selectName', this.chooseCard)
      if (this.chooseCard == 2) {
        this.showQrcode()
      } else if (this.chooseCard == 4) {
        this.showQrcode()
      } else if (this.chooseCard == 7) {
        this.showQrcodeNew(1)
      } else if (this.chooseCard == 6) {
        this.showQrcodeNew(2)
      }
    })
  },
  data () {
    return {
      shoWData: false,
      verSuccess: false,
      title: '教师工作台',
      titleArr: ['', '', '教师工作台', '', '教育局工作台', '', '家长中心', '学生中心', '培训机构工作台'],
      app_type: '',
      titleStr: {},
      succ: false,
      theme2: 'light',
      code_show: true,
      innnerModal: false,
      formValidate: {
        phone: "",
        password: "",
        idCard: '',
      },
      ruleValidate: {
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        idCard: [{ required: true, message: "请输入身份证号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      chooseCard: "2",
      qrcode: '',
    }
  },
  methods: {
    selectName (name) {
      this.chooseCard = name
      this.title = this.titleArr[name]
      clearInterval(timer)
      if (this.code_show) {
        this.showQrcode()
      }
      this.succ = false
      localStorage.setItem('chooseCard', name)
    },
    // 关闭弹框
    cancelModal () {
      this.code_show = true
      clearInterval(timer)
      localStorage.removeItem('chooseCard')
    },
    // 切换登录方式
    changeLoginType (val) {
      this.code_show = !this.code_show
      if (val == 1) {
        clearInterval(timer)
      } else {
        this.showQrcode()
      }
    },
    // 学校和机关扫码
    showQrcode () {
      if (this.chooseCard == 2 || this.chooseCard == 4) {
        getLoginCode().then(res => {
          if (res.data.code) {
            let codeid = res.data.data
            let leixing_login = 2
            if (this.chooseCard == 2) {
              leixing_login = 1
            }
            let obj = JSON.stringify({ code_no: codeid, type: leixing_login })
            this.qrcode = obj
            if (!this.code_show) {
              clearInterval(timer)
              return
            }
            timer = setInterval(() => {
              getUserDataForCode({ code_no: codeid }).then(response => {
                if (response.data.code == 1) {
                  this.succ = true
                } else if (response.data.code == 2) {
                  clearInterval(timer)
                  //机关和学校
                  let dataAll = response.data.data
                  storeUserInfo(dataAll)
                  parent.location.reload()
                }
              })
            }, 1000)
          } else {
            this.$Message.info(res.data.msg)
          }
        })
      } else if (this.chooseCard == 7) {
        // console.log('7')
        this.showQrcodeNew(1)
      } else if (this.chooseCard == 6) {
        // console.log('6')
        this.showQrcodeNew(2)
      }
    },
    // 学生和家长扫码登录切换
    showQrcodeNew (val) {
      getStudentJiazhangLoginCode({ type: val }).then(res => {
        if (res.data.code) {
          let codeid = res.data.data
          let leixing_login = 3//学生登录类型
          //家长登录类型
          if (this.chooseCard == 6) {
            leixing_login = 4
          }
          let obj = JSON.stringify({ code_no: codeid, type: leixing_login })
          this.qrcode = obj
          if (!this.code_show) {
            clearInterval(timer)
            return
          }
          timer = setInterval(() => {
            getStudentJiazhangDataForCode({ code_no: codeid }).then(response => {
              if (response.data.code == 1) {
                this.succ = true
              } else if (response.data.code == 2) {
                // 存储登录之后信息
                clearInterval(timer)
                let dataAll = response.data.data
                if (dataAll.student_info) {
                  dataAll.organ_type = "parent"
                  dataAll.user_id = 6
                  storeUserInfo(dataAll)
                } else {
                  dataAll.organ_type = "student"
                  storeUserInfo(dataAll)
                }
                parent.location.reload()
              }
            })
          }, 1000)
        } else {
          this.$Message.info(res.data.msg)
        }
      })
    },
    // 提交图形验证码
    submit (val, isLogin) {
      this.verSuccess = isLogin
      // console.log('掉登录', val, isLogin)
      if (isLogin) {
        this.handleSubmit('formCustom')
      }
    },
    // 点登录按钮登录
    handleSubmit (name) {
      if (this.chooseCard == 7) {// 学生登录
        this.$refs[name].validate((valid) => {
          if (valid) {
            // this.shoWData = true
            // if (this.verSuccess) {
            // this.shoWData = false
            let data = { 'id_card': this.formValidate.idCard.trim(), 'password': this.formValidate.password.trim() }
            this.$store.dispatch('studentLogin', data).then(res => {
              if (res == "ok") {
                this.$Message.success("登录成功")
                parent.location.reload()
              } else {
                this.$Message.error("登录失败")
              }
            }).catch(err => {
              this.$Message.error(err.message)
              parent.location.reload()
            })
            // }
          }
        })
      } else if (this.chooseCard == 6) {//家长登录
        this.$refs[name].validate((valid) => {
          if (valid) {
            // this.shoWData = true
            // if (this.verSuccess) {
            //   this.shoWData = false
            let data = { 'mobile': this.formValidate.phone.trim(), 'password': this.formValidate.password.trim() }
            this.$store.dispatch('parentLogin', data).then(res => {
              if (res == "ok") {
                this.$Message.success("登录成功")
                parent.location.reload()
              } else {
                this.$Message.error("登录失败")
              }
            }).catch(err => {
              this.$Message.error(err.message)
              parent.location.reload()
            })
            // }
          }
        })
      } else {//学校和机关登录
        this.$refs[name].validate((valid) => {
          if (valid) {
            // this.shoWData = true
            // if (this.verSuccess) {
            //   this.shoWData = false
            let data = {
              user_login: this.formValidate.phone.trim(),
              user_pass: this.formValidate.password.trim(),
              type: this.chooseCard,
              login_type: 1,
              menhu_url: window.location.origin
            }
            this.$store.dispatch('userLogin', data).then(res => {
              if (res == "ok") {
                this.$Message.success("登录成功")
                parent.location.reload()
              } else {
                this.$Message.error("登录失败")
              }
            }).catch(err => {
              this.$Message.error(err.message)
              parent.location.reload()
            })
            // }
          } else {
            this.$Message.error("登录失败")
          }
        })
      }
    },
    // 根据type返回加密的密码
    // encodeSecret (str) {
    //   if (this.chooseCard == "7") {
    //     return Base64.encode(AES_SECRET.encrypt(str))
    //   } else if (this.chooseCard == "2" || this.chooseCard == "4") {
    //     return this.$md5(str)
    //   } else {
    //     return str
    //   }
    // },
    changeVisible (val) {
      this.$emit("update:modal", val)
      this.$refs["formCustom"]?.resetFields()
    },
  },
  mounted () { },
}
</script>
<style lang="less" scoped>
/deep/.ivu-modal-body {
  padding: 16px 16px 0 16px;
}
.titleCss {
  p {
    padding: 0 0 20px 0;
    color: #999;
  }
}
.saomaType {
  p {
    text-align: center;
    padding: 20px 0 20px 0;
    color: #0068b7;
    cursor: pointer;
  }
}
.qrcode {
  width: 180px;
  margin: 0 auto;
  padding: 10px;
  background: linear-gradient(#dcdee2, #dcdee2) left top,
    linear-gradient(#dcdee2, #dcdee2) left top,
    linear-gradient(#dcdee2, #dcdee2) right top,
    linear-gradient(#dcdee2, #dcdee2) right top,
    linear-gradient(#dcdee2, #dcdee2) left bottom,
    linear-gradient(#dcdee2, #dcdee2) left bottom,
    linear-gradient(#dcdee2, #dcdee2) right bottom,
    linear-gradient(#dcdee2, #dcdee2) right bottom;
  background-repeat: no-repeat;
  border: 2px solid transparent;
  background-size: 0.2vw 1.5vw, 1.5vw 0.2vw;
  border-radius: 10px;
}
.ivu-form {
  padding: 0px 100px 20px;
  margin: 20px 40px 0px;
}
/deep/.ivu-form-item {
  margin-bottom: 30px;
}
/deep/.ivu-menu-horizontal {
  display: inline-block;
}
/deep/.ivu-menu-item {
  font-size: 16px;
  margin: 0 20px;
}
/deep/.ivu-btn-primary {
  background-color: #0068b7;
  border-color: #0068b7;
}
/deep/.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item {
  color: #333;
  padding: 0;
}
/deep/.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item-active,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item:hover,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu-active,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu:hover {
  color: #0068b7;
  font-weight: 500;
  border-color: #0068b7;
}
/deep/.ivu-menu-horizontal.ivu-menu-light:after {
  background: #fff;
}
/deep/.ivu-modal-close .ivu-icon-ios-close {
  font-weight: bold;
  color: #0068b7;
}
</style>
