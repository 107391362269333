import { getmenhuIndex } from '@/api'
import { Message } from 'view-design'
const state = {
  dataInfo: []

}
const mutations = {
  SETDATAINFO (state, dataInfo) {
    state.dataInfo = dataInfo
  },
}
const actions = {
  // 通过api里面的接口函数调用，向服务器发请求，获取服务器数据
  getmenhuIndex (context) {
    return new Promise((resolve, reject) => {
      let data = { organ_id: localStorage.getItem('organ_id'), url: window.location.origin }
      getmenhuIndex(data).then(res => {
        if (res.data.code == 1) {
          context.commit('SETDATAINFO', res.data.data)
          resolve('ok')
        } else {
          Message.error(res.data.msg)
          reject(Error(res.data.msg))
        }

      })
    })

  },
}
const getters = {
}
export default {
  state,
  mutations,
  actions,
  getters
}