<template><!-- 头部 -->
  <div class="header">
    <div class="contain-wrap">
      <div class="left">
        <img :src="lookOriginJSY(titleImg())"
             alt="logo">
        <router-link to="/home">
          <h2 style="font-size: 32px;">{{ titleMsg() }}</h2>
        </router-link>
      </div>
      <div class="right">
        <span class="diquCss">{{ AreaName }}</span>
        <span class="schoolCss"
              v-if="userTrueName">{{ organName }}-{{ userTrueName }}</span>
        <a class="gztCss"
           v-if="userTrueName"
           @click="enterGongzuotai"
           target="_blank">进入工作台</a>
        <Button type="primary"
                v-if="!token"
                style="font-size: 20px;width:90px;height:35px;border-radius: 10px;"
                @click="loginClick">登录</Button>
        <Button v-else
                type="text"
                style="font-size: 16px;font-weight: bold;color: #0068b7;"
                @click="loginOut">退出</Button>
        <loginView :modal.sync="modal"></loginView>
      </div>
    </div>
  </div>
</template>

<script>
import loginView from '@/views/login/index.vue'
import { lookOriginJSY } from '@/utils/linkUtilsJSY.js'
import { xxb_MenhuToken } from '@/api/login'
export default {
  components: {
    loginView,
  },
  props: {
    headerInfomatian: {
      type: Object,
      default: () => {
        return { detail: [{}] }
      }
    },
  },
  data () {
    return {
      chooseCard: "",
      lookOriginJSY,
      modal: false,
      token: localStorage.getItem('GetOrganId'),
      userTrueName: localStorage.getItem('GetUserTrueName'),//姓名
      organName: localStorage.getItem('GetOrganName'),
      AreaName: localStorage.getItem('GetAreaName')
    }
  },
  methods: {
    // 点击进入工作台
    enterGongzuotai () {
      this.chooseCard = localStorage.getItem('chooseCard')
      let obj = {
        'user_id': localStorage.getItem('GetUserId'),
        'organ_id': localStorage.getItem('GetOrganId'),
        'organ_name': localStorage.getItem('GetOrganName'),
        'area_name': localStorage.getItem('GetAreaName'),
        'user_name': localStorage.getItem('GetUserTrueName'),
        'organ_type': localStorage.getItem('organ_type'),
        'jianhuren_name': localStorage.getItem('jianhuren_name'),
        'studentsArr': localStorage.getItem('studentsArr'),
        'position_name': localStorage.getItem('position_name'),
      }
      let url = ""
      if (window.location.origin == "http://localhost:8080") {
        url = "http://172.27.224.29:8081/"
      } else if (window.location.origin == "http://menhu.jsytest.top" || window.location.origin == "https://menhu.jsytest.top") {
        url = "https://xxbwww.jsytest.top/"
      } else {
        url = "https://www.jzyxxb.cn/"
      }
      if (this.chooseCard == 7 || this.chooseCard == 6) {
        window.open(url + 'shouyeStudent?' + 'obj=' + JSON.stringify(obj), '_blank')
      } else {
        window.open(url + 'shouye?' + 'obj=' + JSON.stringify(obj), '_blank')
      }
    },
    titleMsg () {
      this.$cookie.set('titleMsg', this.headerInfomatian?.detail?.find(item => item.base_id == 1)?.content ?? '')
      return this.headerInfomatian?.detail?.find(item => item.base_id == 1)?.content ?? ''
    },
    titleImg () {
      this.$cookie.set('titleImg', this.headerInfomatian?.detail?.find(item => item.base_id == 39)?.content ?? '')
      return this.headerInfomatian?.detail?.find(item => item.base_id == 39)?.content ?? ''
    },
    loginClick () {
      this.modal = true
      this.$bus.$emit('selectName', '2')
    },
    loginOut () {
      this.$store.dispatch('loginOut').then(res => {
        if (res == "ok") {
          this.$Message.success("退出成功")
          // 将当前页面刷新
          window.location.reload()
        } else {
          this.$Message.error("退出失败")
        }
      })
    }
  },
  mounted () {
    // console.log(window.location)
  }
}

</script>
<style lang="less" scoped>
// /* 当窗口宽度小于或等于600px时 */
// @media screen and (min-width: 1400px) {
//   .header {
//     .contain-wrap {
//       width: 1200px; /* 在小屏幕设备上调整宽度 */
//     }
//   }
// }
// /* 当窗口宽度在601px到900px之间时 */
// @media screen and (min-width: 960px) and (max-width: 1200px) {
//   .header {
//     .contain-wrap {
//       width: 1200px;
//     }
//   }
// }
.header {
  background: #fff;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  .contain-wrap {
    width: 1200px;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 1200px;
    margin: 0 auto;
  }
}
.containView {
  height: 50px;
  background: #f7f7f7;
  box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.1);
  .main-view .tabs-wrapper {
    width: 1200px;
    margin: 0 auto;
    .lunbo {
      padding: 0 0;
    }
    .tabs {
      width: 100%;
    }
  }
}
.left {
  display: flex;
  align-items: center;

  img {
    width: 80px;
    height: auto;
  }

  h2 {
    font-size: 24px;
    color: #333;
    margin-left: 10px;
  }
}

.right {
  .diquCss,
  .schoolCss,
  .gztCss {
    font-size: 16px;
    font-weight: bold;
    color: #0068b7;
    vertical-align: middle;
  }
  .diquCss {
    margin-right: 20px;
  }
  .schoolCss {
    margin-right: 20px;
  }
  .gztCss {
  }
  a {
    color: #333;
  }
}
/deep/.ivu-btn-primary {
  background-color: #0068b7;
  border-color: #0068b7;
}
</style>