// 存储用户信息
export const storeUserInfo = (item) => {
  // console.log(item)
  localStorage.setItem('GetUserId', item.user_id ? item.user_id : item.id_card)
  localStorage.setItem('GetOrganId', item.organ_id ? item.organ_id : item.student_info[0].organ_id)
  localStorage.setItem('GetBranchId', item.branch_id)
  localStorage.setItem('GetUserTrueName', item.user_truename ? item.user_truename : item.student_name ? item.student_name : item.student_info[0].student_name)
  localStorage.setItem('GetBranchName', item.branch_name)
  localStorage.setItem('GetPositionName', item.position_name ? item.user_truename : '')
  localStorage.setItem('GetOrganName', item.organ_name ? item.organ_name : item.student_info[0].organ_name)
  localStorage.setItem('GetShengId', item.sheng_id)
  localStorage.setItem('GetShiId', item.shi_id)
  localStorage.setItem('GetQuxianId', item.quxian_id)
  localStorage.setItem('GetShengName', item.sheng_name)
  localStorage.setItem('GetShiName', item.shi_name)
  localStorage.setItem('GetQuxianName', item.quxian_name)
  localStorage.setItem('GetAreaName', item.area_name ? item.area_name : item.diqu ? item.diqu : item.student_info[0].diqu)
  localStorage.setItem('GetToken', item.GetToken)
  localStorage.setItem('jianhuren_name', item.jianhuren_name)
  localStorage.setItem('organ_type', item.organ_type)
  localStorage.setItem('studentsArr', JSON.stringify(item.student_info))
  localStorage.setItem('class_id', item.class_id)
  localStorage.setItem('class_name', item.class_name)
  localStorage.setItem('class_start_time', item.class_start_time)
  localStorage.setItem('grade', item.grade)
  localStorage.setItem('sex', item.sex)
  localStorage.setItem('xueduan_id', item.xueduan_id)
  localStorage.setItem('xueduan_name', item.xueduan_name)
  localStorage.setItem('area_id', item.area_id ? item.area_id : item.student_info ? item.student_info[0].area_id : '')
}
export const clearUserInfo = () => {
  localStorage.removeItem('GetUserId')
  localStorage.removeItem('GetOrganId')
  localStorage.removeItem('GetBranchId')
  localStorage.removeItem('GetUserTrueName')
  localStorage.removeItem('GetBranchName')
  localStorage.removeItem('GetPositionName')
  localStorage.removeItem('GetOrganName')
  localStorage.removeItem('GetShengId')
  localStorage.removeItem('GetShiId')
  localStorage.removeItem('GetQuxianId')
  localStorage.removeItem('GetShengName')
  localStorage.removeItem('GetShiName')
  localStorage.removeItem('GetQuxianName')
  localStorage.removeItem('GetAreaName')
  localStorage.removeItem('GetToken')
  localStorage.removeItem('jianhuren_name')
  localStorage.removeItem('area_id')
  localStorage.removeItem('organ_type')
  localStorage.removeItem('studentsArr')
  localStorage.removeItem('class_id')
  localStorage.removeItem('class_start_time')
  localStorage.removeItem('grade')
  localStorage.removeItem('id_card')
  localStorage.removeItem('sex')
  localStorage.removeItem('xueduan_id')
  localStorage.removeItem('xueduan_name')
  localStorage.removeItem('chooseCard')
  localStorage.removeItem('class_name')

}