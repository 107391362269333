<template>
  <div class="containView">
    <div class="main-view">
      <div class="tabs-wrapper">
        <Tabs class="tabs"
              @on-click="handleTab"
              :animated="false"
              :value="tabP0">
          <TabPane v-for="(item, index) in tabsArray"
                   :key="index"
                   :label="item.titilemsg"
                   :name="item.base_id.toString()">
            <!-- :name="`tabP${index}`" -->
          </TabPane>
        </Tabs>
      </div>
      <div class="searchArea">
        <!-- <form action="###" class="searchForm">
                <input type="text" id="autocomplete" v-model="keyword" />
                <button type="button" @click="goSearch">搜索</button>
            </form> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    infomation: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    infomation: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.tabsArray = newVal.find(item => item.location === 2)?.detail ?? []
        }
      },
      deep: true,
      immediate: true
    },
    '$route.path' (newPath) {// 监听路由变化显示高亮导航
      // console.log('newPath', this.$route)
      if (newPath == "/home") {
        this.handleTab('2')
      } else if (newPath == "/list/jianxunList") {
        this.handleTab('3')
      } else if (newPath == "/kongjian/jiguanKongjian") {
        this.handleTab('4')
      } else if (newPath == "/ziyuan") {
        this.handleTab('5')
      } else if (newPath == "/saishihuodong/saishiList") {//saishiList
        this.handleTab('6')
      } else if (newPath == "/publicService/serviceHome") {//公众服务
        this.handleTab('42')
      } else if (newPath == "/download") {//saishiList
        this.handleTab('7')
      } else if (newPath == "/homeDetail" || newPath == "/homeDetailPdf" || newPath == "/list/jianxunList" || newPath == "/list/tongzhiList" || newPath == "/list/wenjianList") {//saishiList
        this.tabP0 = '3'
      } else if (newPath == "/beikeDetail") {
        this.tabP0 = '5'
      } else if (newPath == '/saishihuodong/saishiDetail') {
        this.tabP0 = '6'
      } else if (newPath == "/kongjian/departKongjian" || newPath == "/kongjian/schoolKongjian" || newPath == "/kongjian/classKongjian" || newPath == "/kongjian/teacherKongjian" || newPath == "/kongjian/schoolList"
        || newPath == "/kongjian/classList" || newPath == "/kongjian/teacherList") {
        this.tabP0 = '4'
      }
    }
  },
  data () {
    return {
      tabP0: '',
      tabsArray: [],
    }
  },
  methods: {
    handleTab (name) {
      console.log('tab0', name)
      localStorage.removeItem('school_organId')
      this.tabP0 = name
      if (name == 2) {
        this.$router.push({
          name: 'home'
        })
      } else if (name == 3) {
        this.$router.push({
          name: 'jianxunList',
        })
      } else if (name == 4) {
        this.$router.push({
          name: 'kongjian'
        })
      } else if (name == 5) {
        this.$router.push({
          name: 'ziyuan'
        })
      } else if (name == 6) {
        this.$router.push({
          name: 'saishihuodong'
        })
      } else if (name == 42) {
        this.$router.push({
          name: 'publicService'
        })
      } else if (name == 7) {
        this.$router.push({
          name: 'download'
        })
      }
    },
  },
  created () {

  },
  mounted () {
    if (localStorage.getItem('tab0')) {
      this.tabP0 = localStorage.getItem('tab0')
    }
  }
}
</script>

<style lang="less" scoped>
.containView {
  width: 1200px;
  height: 60px;
  margin: 0 auto;
  background: #498ac8;
  box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.1);
  .main-view .tabs-wrapper {
    width: 1200px;
    margin: 0 auto;
    .lunbo {
      padding: 0 0;
    }
    .tabs {
      width: 100%;
    }
  }
}
/deep/.ivu-tabs-nav {
  height: 60px;
}
/deep/.ivu-btn {
  border: 2px solid;
}
/deep/.ivu-tabs-nav-container {
  line-height: 2.2;
}
/deep/ .ivu-tabs-nav .ivu-tabs-tab {
  color: #fff;
  font-size: 20px !important;
  // font-weight: bold !important;
  padding: 8px 38px;
}
/deep/ .ivu-tabs-nav .ivu-tabs-tab-active {
  color: #fff;
  font-size: 20px !important;
  background: #0068b7;
  font-weight: bold !important;
}
/deep/ .ivu-tabs-nav .ivu-tabs-tab:hover {
  color: #fff;
  font-size: 20px !important;
  font-weight: bold !important;
}
/deep/ .ivu-tabs-ink-bar {
  height: 0;
  background-color: #0068b7;
}
/deep/.ivu-tabs-bar {
  border-bottom: 0px;
}
/deep/.ivu-tabs-nav .ivu-tabs-tab {
  margin-right: 0;
}
</style>