import Vue from "vue";
// import Vuex from "vuex";
import Vuex from "vuex"
// 需要使用插件一次
Vue.use(Vuex)
import user from "./user";
import home from "./home";
export default new Vuex.Store({
    modules: {
        user,
        home,
    }
});
