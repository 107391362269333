function preRoute(routes, isInner = false) {
  return routes.map(route => {
    // console.log('最外层的route，如果没有配置component，默认设为layout', route)
    // 没有配置name的，取path(替换/)
    if (!route.name) {
      route.name = route.path.replaceAll('/', '')
    }
    // 快捷配置：title赋到meta.title
    if (!route?.meta?.title) {
      route.meta = route.meta || {}
      // 如果title也未配置，设置为空字符串
      if (route.title) {
        route.meta.title = route.title
      } else {
        console.warn('有菜单title未设置', route)
        route.meta.title = ''
      }
    }
    // 最外层的route，如果没有配置component，默认设为layout
    if (!isInner && !route.component) {
      route.component = () => import('@/views/layout')
    }
    // 如果没有配置redirect，默认设为第一个子项
    if (!route.redirect && route.children && route.children.length > 0) {
      route.redirect = route.path.replaceAll('/', '') + '/' + route.children[0].path
    }
    if (route.children) {
      route.children = preRoute(route.children, true)
    }
    return route
  })
}

export {
  preRoute
}
