// 对外暴漏一个函数
// 存储token
export const setToken = (token) => {
    // console.log(token)
    localStorage.setItem('TOKEN', token)
}

// 获取token
export const getToken = () => {
    return localStorage.getItem('TOKEN') || ''
}
// 清空缓存
export const deleteToken = () => {
    localStorage.removeItem('TOKEN')
}