import axios from '@/libs/api.request'

// 登录
export const menhuindexlogin = (data) => {
  return axios.request({
    url: 'v1/login',
    data,
    method: 'post'
  })
}
// 退出
export const loginOut = (data) => {
  return axios.request({
    url: 'v1/menhuindex',
    data,
    method: 'post'
  })
}
//查询门户登录token是否存在
export const xxb_MenhuToken = (data) => {
  return axios.request({
    url: 'v1/xxb_MenhuToken',
    data,
    method: 'post'
  })
}
// 学生登录
export const xxb_StudentLogin = (data) => {
  return axios.request({
    url: 'v1/xxb_StudentLogin',
    data,
    method: 'post'
  })
}
// 学生扫码登录
export const getStudentJiazhangDataForCode = (data) => {
  return axios.request({
    url: 'v1/getStudentJiazhangDataForCode',
    data,
    method: 'post'
  })
}
// 家长登录
export const xxb_ParentLogin = (data) => {
  return axios.request({
    url: 'v1/xxb_ParentLogin',
    data,
    method: 'post'
  })
}
// 家长扫码登录
export const getStudentJiazhangLoginCode = (data) => {
  return axios.request({
    url: 'v1/getStudentJiazhangLoginCode',
    data,
    method: 'post'
  })
}
//扫码登录  扫码获取code
export const getLoginCode = () => {
  return axios.request({
    url: 'getLoginCode',
    method: 'post',
  })
}
//扫码登录  轮询登录
export const getUserDataForCode = (data) => {
  return axios.request({
    url: 'getUserDataForCode',
    method: 'post',
    data: data
  })
}
