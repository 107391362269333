import axios from '@/libs/api.request'


// 轮播列表
export const getmenhuIndex = (data) => {
  return axios.request({
    url: 'v1/menhuindex',
    data,
    method: 'post'
  })
}
// 列表
export const getjianxunlist = (data) => {
  return axios.request({
    url: 'v1/getjianxunlist',
    data,
    method: 'post'
  })
}
// 简讯详情
export const jXdetail = (data) => {
  return axios.request({
    url: 'v1/jXdetail',
    data,
    method: 'post'
  })
}
// 通知详情
export const mh_getMenhuInform = (data) => {
  return axios.request({
    url: 'v1/mh_getMenhuInform',
    data,
    method: 'post'
  })
}
// 门户接口-------------------------------start
// 根据域名获取organ_id
export const mh_urlData = (data) => {
  return axios.request({
    url: 'v1/mh_urlData',
    data,
    method: 'post'
  })
}
// 学校列表
export const mh_getSchoolList = (data) => {
  return axios.request({
    url: 'v1/mh_getSchoolList',
    data,
    method: 'post'
  })
}
// 空间首页基础数据
export const mh_getkongjianshouye = (data) => {
  return axios.request({
    url: 'v1/mh_getkongjianshouye',
    data,
    method: 'post'
  })
}
// 空间首页数量统计
export const mh_getKongjianMassage = (data) => {
  return axios.request({
    url: 'v1/mh_getKongjianMassage',
    data,
    method: 'post'
  })
}
// 部门空间
export const mh_getBranchKongjian = (data) => {
  return axios.request({
    url: 'v1/mh_getBranchKongjian',
    data,
    method: 'post'
  })
}
// 学校空间
export const getSchoolMessage = (data) => {
  return axios.request({
    url: 'v1/getSchoolMessage',
    data,
    method: 'post'
  })
}
// 荣誉列表
export const mh_getRongyuList = (data) => {
  return axios.request({
    url: 'v1/mh_getRongyuList',
    data,
    method: 'post'
  })
}
// 相册列表
export const mh_getPhotoList = (data) => {
  return axios.request({
    url: 'v1/mh_getPhotoList',
    data,
    method: 'post'
  })
}

// 门户接口-------------------------------end
// 授课反思详情
export const shoukeDetail = (data) => {
  return axios.request({
    url: 'v1/shoukeDetail',
    data,
    method: 'post'
  })
}
// 2024-09-23基本设置接口修改dyj---------------------start
// 门户轮播详情(学校邦)
export const mh_MenhuLunboDetail1 = (data) => {
  return axios.request({
    url: 'v1/mh_MenhuLunboDetail1',
    data,
    method: 'post'
  })
}
export const mh_MenhuDbLunboDetail1 = (data) => {
  return axios.request({
    url: 'v1/mh_MenhuDbLunboDetail1',
    data,
    method: 'post'
  })
}
export const mh_MenhuDibuLunboDetail1 = (data) => {
  return axios.request({
    url: 'v1/mh_MenhuDibuLunboDetail1',
    data,
    method: 'post'
  })
}
// 2024-09-23基本设置接口修改dyj---------------------end