const hidden = true
// 路由配置信息
export default [
  {
    path: '/schoolKongjian',
    name: 'schoolKongjian',
    title: '学校空间',
    meta: {
      name: '学校空间'
    },
    component: () => import('@/views/kongjian/schoolndex.vue'),
  },
  // 重定向，在项目跑起来的时候，访问/。立马返回到首页
  {
    path: '*',
    title: '首页',
    redirect: '/home'
  }
]