import { reactive } from 'vue'

const hidden = true
// 路由配置信息
export default [
  {
    path: '/home',
    title: '首页',
    meta: {
      name: '首页'
    },
    component: () => import('@/views/Home/index.vue'),
  },
  {
    path: '/homeDetail',
    title: '详情',
    meta: {
      name: '详情'
    },
    component: () => import('@/views/mainView/homeDetail.vue'),
  },
  {
    path: '/shoukeFansi',
    title: '详情',
    meta: {
      name: '详情'
    },
    component: () => import('@/views/mainView/shoukeFansi.vue'),
  },
  {
    path: '/homeDetailPdf',
    title: '详情',
    meta: {
      name: '详情'
    },
    component: () => import('@/views/mainView/homeDetailPdf.vue'),
  },
  {
    path: '/kongjian',
    name: 'kongjian',
    title: '空间',
    meta: {
      name: '空间'
    },
    component: () => import('@/views/kongjian/index.vue'),
    children: [
      {
        path: 'jiguanKongjian',
        name: 'jiguanKongjian',
        title: '主页',
        meta: {
          name: '主页',
        },
        component: () => import('@/views/kongjian/jiguanKongjian')
      },
      {
        path: 'departList',
        name: 'departList',
        title: '部门列表',
        meta: {
          name: '部门列表',
        },
        component: () => import('@/views/kongjian/spaceBranch/index.vue')
      },
      {
        path: 'departKongjian',
        name: 'departKongjian',
        title: '部门空间',
        meta: {
          name: '部门空间',
        },
        component: () => import('@/views/kongjian/jiguanKongjian/departKongjian.vue')
      },
      {
        path: 'schoolList',
        name: 'schoolList',
        title: '学校列表',
        meta: {
          name: '学校列表',
        },
        component: () => import('@/views/kongjian/components/school/schoolList.vue')
      },
      {
        path: 'schoolKongjian',
        name: 'schoolKongjian',
        title: '学校空间',
        meta: {
          name: '学校空间',
        },
        component: () => import('@/views/kongjian/schoolKongjian')
      },
      {
        path: 'rongyuList',
        name: 'rongyuList',
        title: '荣誉列表',
        meta: {
          name: '荣誉列表',
        },
        component: () => import('@/views/kongjian/components/school/rongyuList.vue')
      },
      {
        path: 'xiangceList',
        name: 'xiangceList',
        title: '相册列表',
        meta: {
          name: '相册列表',
        },
        component: () => import('@/views/kongjian/components/school/xiangceList.vue')
      },
      {
        path: 'classList',
        name: 'classList',
        title: '班级列表',
        meta: {
          name: '班级列表',
        },
        component: () => import('@/views/kongjian/spaceClass/index.vue')
      },
      {
        path: 'classKongjian',
        name: 'classKongjian',
        title: '班级空间',
        meta: {
          name: '班级空间',
        },
        component: () => import('@/views/kongjian/spaceClass/spanceClassInfo.vue')
      },
      {
        path: 'teacherList',
        name: 'teacherList',
        title: '教师',
        meta: {
          name: '教师',
        },
        component: () => import('@/views/kongjian/spaceTeacher/index.vue')
      },
      {
        path: 'teacherKongjian',
        name: 'teacherKongjian',
        title: '教师空间',
        meta: {
          name: '教师空间',
        },
        component: () => import('@/views/kongjian/spaceTeacher/spaceTeacherInfo.vue')
      },
      {
        path: 'studentList',
        name: 'studentList',
        title: '学生',
        meta: {
          name: '学生',
        },
        component: () => import('@/views/kongjian/spaceStudent/index.vue')
      },
      {
        path: 'studentKongjian',
        name: 'studentKongjian',
        title: '学生空间',
        meta: {
          name: '学生空间',
        },
        component: () => import('@/views/kongjian/spaceStudent/spaceStudentInfo.vue')
      },
      {
        path: 'parentList',
        name: 'parentList',
        title: '家长',
        meta: {
          name: '家长',
        },
        component: () => import('@/views/kongjian/spaceParent/index.vue')
      },
      {
        path: 'parentKongjian',
        name: 'parentKongjian',
        title: '家长空间',
        meta: {
          name: '家长空间',
        },
        component: () => import('@/views/kongjian/spaceParent/spaceParentInfo.vue')
      },
    ]
  },
  {
    path: '/ziyuan',
    name: 'ziyuan',
    title: '资源',
    meta: {
      name: '资源'
    },
    component: () => import('@/views/ziyuan/index.vue'),
  },
  {
    path: '/ziyuanDetail',
    name: 'ziyuanDetail',
    title: '资源详情',
    meta: {
      name: '资源详情'
    },
    component: () => import('@/views/ziyuan/ziyuanDetail')
  },
  {
    path: '/beikeDetail',
    name: 'beikeDetail',
    title: '备课详情',
    meta: {
      name: '备课详情'
    },
    component: () => import('@/views/ziyuan/beikeDetail')
  },
  {
    path: '/otherDetail',
    name: 'otherDetail',
    title: '其他详情',
    meta: {
      name: '其他详情'
    },
    component: () => import('@/views/publicService/otherDetail')
  },
  {
    path: '/publicService',
    name: 'publicService',
    title: '公众服务',
    meta: {
      name: '公众服务'
    },
    component: () => import('@/views/publicService/index.vue'),
    children: [
      {
        path: 'serviceHome',
        name: 'serviceHome',
        title: '公众服务',
        meta: {
          name: '公众服务',
          // keepAlive: true
        },
        component: () => import('@/views/publicService/serviceHome/index.vue')
      },
      {
        path: 'serviceSchool',
        name: 'serviceSchool',
        title: '学校',
        meta: {
          name: '学校',
          // keepAlive: true
        },
        component: () => import('@/views/publicService/serviceSchool/index.vue')
      },
      {
        path: 'serviceKindergarten',
        name: 'serviceKindergarten',
        title: '幼儿园',
        meta: {
          name: '幼儿园',
          // keepAlive: true
        },
        component: () => import('@/views/publicService/serviceKindergarten/index.vue')
      },
      {
        path: 'serviceOrgan',
        name: 'serviceOrgan',
        title: '机构',
        meta: {
          name: '机构',
          // keepAlive: true
        },
        component: () => import('@/views/publicService/serviceOrgan/index.vue')
      },
      {
        path: 'serviceSynthesize',
        name: 'serviceSynthesize',
        title: '综合',
        meta: {
          name: '综合',
          // keepAlive: true
        },
        component: () => import('@/views/publicService/serviceSynthesize/index.vue'),
      },
      {
        path: 'serviceDetail',
        name: 'serviceDetail',
        title: '综合文章详情',
        meta: {
          name: '综合文章详情',
          keepAlive: true
        },
        component: () => import('@/views/publicService/serviceSynthesize/serviceDetail.vue'),
      },
    ]
  },
  {
    path: '/saishihuodong',
    name: 'saishihuodong',
    title: '赛事',
    meta: {
      name: '赛事'
    },
    component: () => import('@/views/saishihuodong/index.vue'),
    children: [
      {
        path: 'saishiList',
        name: 'saishiList',
        title: '赛事列表',
        meta: {
          name: '赛事列表',
          keepAlive: true
        },
        component: () => import('@/views/saishihuodong/saishiList.vue')
      },
      {
        path: 'saishiDetail',
        name: 'saishiDetail',
        title: '赛事详情',
        meta: {
          name: '赛事详情',
          keepAlive: true
        },
        component: () => import('@/views/saishihuodong/saishiDetail.vue')
      },
      {
        path: 'zuopinDetail',
        name: 'zuopinDetail',
        title: '作品详情',
        meta: {
          name: '作品详情',
          keepAlive: true
        },
        component: () => import('@/views/saishihuodong/zuopinDetail.vue')
      },
    ]
  },
  {
    path: '/detail',
    hidden,
    name: 'detail',
    title: '详情',
    meta: {
      name: '详情'
    },
    component: () => import('@/views/mainView/shouye/more/detail/index.vue')
  },
  // 文件详情
  {
    path: '/pdfDetail', name: 'pdfDetail', hidden, title: '文件详情', meta: {
      name: '文件详情'
    }, component: () => import('@/views/mainView/shouye/more/detail/pdfDetail.vue')
  },
  {
    path: '/download',
    name: 'download',
    title: '下载',
    meta: {
      name: '下载'
    },
    component: () => import('@/views/download/index.vue'),
  },
  {
    path: '/list',
    title: '列表信息',
    children: [
      {
        path: 'jianxunList',
        name: 'jianxunList',
        title: '简讯列表',
        meta: {
          name: '资讯',
          keepAlive: true
        },
        component: () => import('@/views/mainView/shouye/more/jianxunList.vue')
      },
      {
        path: 'tongzhiList',
        name: 'tongzhiList',
        title: '通知列表',
        meta: {
          name: '资讯',
          keepAlive: true
        },
        component: () => import('@/views/mainView/shouye/more/tongzhiList.vue')
      },
      {
        path: 'wenjianList',
        name: 'wenjianList',
        title: '文件列表',
        meta: {
          name: '资讯',
          keepAlive: true
        },
        component: () => import('@/views/mainView/shouye/more/wenjianList.vue')
      },
    ]
  },
  {
    path: '/login_jcpt',
    title: '沁教云基础平台',
    meta: {
      name: '沁教云基础平台'
    },
    component: () => import('@/views/jichuPingtai/login_jcpt.vue'),
  },
  {
    path: '/menhuHome',
    name: 'menhuHome',
    title: '门户平台',
    meta: {
      name: '门户平台'
    },
    component: () => import('@/views/Home/menhuHome'),
  },
  {
    path: '/kongjianHome',
    name: 'kongjianHome',
    title: '空间平台',
    meta: {
      name: '空间平台'
    },
    component: () => import('@/views/Home/kongjianHome'),
  },
  {
    path: '/ziyuanHome',
    name: 'ziyuanHome',
    title: '资源平台',
    meta: {
      name: '资源平台'
    },
    component: () => import('@/views/Home/ziyuanHome'),
  },
  // 重定向，在项目跑起来的时候，访问/。立马返回到首页
  {
    path: '*',
    title: '首页',
    redirect: '/home'
  }
]
