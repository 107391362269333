import CryptoJS from "crypto-js"
//秘钥, 建议设置其他的字符,
var key = CryptoJS.enc.Base64.parse("sasdfkfglleepwek");
// 偏移量, 建议设置其他的字符
var iv = CryptoJS.enc.Base64.parse("ssdeidhfwenddsis");
export default {
	//加密
	encrypt(data) {
		var srcs = CryptoJS.enc.Utf8.parse(data);
		var encrypted = CryptoJS.AES.encrypt(srcs, key, {
			iv: iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		});
		return encrypted.toString();
	},
	//解密
	decrypt(encrypted) {
		var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
			iv: iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		});
		return decrypted.toString(CryptoJS.enc.Utf8);
	}
}
